import { hydrate } from "@/utils/form";

export default class Club {
    id = null;
    address = [];
    appSportTimetableTypes = [];
    bookingCancellationConditionCustomHours = null;
    bookingCancellationConditionType = null;
    smsAccountBalance = 0;
    city = null;
    country = null;
    createdAt = null;
    currency = null;
    deepLink = null;
    description = null;
    district = null;
    email = null;
    enableUserClientWallet = null;
    fixPhoneNumber = null;
    latitude = null
    locale = null;
    logo = null;
    stripeAccountReference = null;
    longitude = null;
    mobilePhoneNumber = null;
    name = null;
    photos = [];
    services =[];
    socialNetworks = [];
    timetables = [];
    timezone = null;
    updatedAt = null
    webSiteUrl = null
    zipCode = null

    constructor (object, options) {
        if ("undefined" !== typeof options) {
            if (options.serialize) {
                this.serialize(object);
            }
        }
    }

    serialize (object) {
        hydrate(this, object);
    }
}
